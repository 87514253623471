import React from "react";
import { LayoutDefault } from "../../components/LayoutDefault";
import Posts from "../../components/blog/Posts/Posts";
import { ConfettiHeader } from "../../components/ConfettiHeader";
import { pageData } from "../../pages-content/dev-notes";
import { PostsWrapper } from "./articles";

export default () => (
  <LayoutDefault>
    <ConfettiHeader {...pageData.header} />
    <PostsWrapper>
      <Posts renderDevNotes />
    </PostsWrapper>
  </LayoutDefault>
);
